/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/widex/'], ['en', 'https://www.hear.com/hearing-aids/brands/widex/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/widex/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/widex/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To restore the authenticity of hearing is the primary objective of the Danish hearing aid manufacturer Widex. Regaining hearing is the discerning customer’s highest priority. The hearing aid brand, situated in Lynge, has been developing innovative and high-quality hearing systems since 1956 to give hearing-impaired people a whole new quality of life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A well, roadworks or birdsong – these are all natural sounds we recognize automatically. Modern hearing aids have the complex task to reproduce these sounds naturally despite electronic amplification. We can usually differentiate between live music and music played through a loudspeaker. We don’t want to have to compromise regarding everyday sounds. Widex’s top priority is research and development – to digitally reproduce the authenticity of hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "authentic-hearing-with-widex-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#authentic-hearing-with-widex-hearing-aids",
    "aria-label": "authentic hearing with widex hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Authentic hearing with Widex hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An important facet of natural hearing is spatial hearing. Due to the exchange of information between the two hearing aids worn, they can localize the source of sound and give a sense of direction to their wearer. Modern hearing aids by Widex are able to suppress disruptive background noise efficiently while emphasizing speech signals. Thanks to this and numerous other technologies and properties, the wearers of Widex hearing aids usually forget that they are wearing hearing aids at all."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "widex-hearing-aids-versatile-models-for-a-customized-hearing-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-hearing-aids-versatile-models-for-a-customized-hearing-experience",
    "aria-label": "widex hearing aids versatile models for a customized hearing experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex hearing aids: versatile models for a customized hearing experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The shape of the human ear is as individual as the human personality. To ensure the hearing aid suits both the ear and your personal lifestyle, Widex offers a large selection of designs. So you choose between a hearing aid with external receiver, like the Passion and Fusion series, or an In-The-Ear (ITE) hearing solution fully inserted in the ear canal. The Widex Dream series is available in the modern “Fashion” version. You are free to choose from 14 colors to make an elegant and slim hearing aid even more individual and in line with your personal preferences."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "widex-news",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-news",
    "aria-label": "widex news permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex News"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Significant improvements regarding “natural hearing” have been made at Widex too. Hearing aids from the “Unique”-product series have the ability to precisely and automatically analyze the hearing environment in real-time. As a result, an individual adaption with active recognition of speech is provided. The new Universal-Chip-Platform furthermore enables a differentiation of 100 distinct hearing situations in order to facilitate an original sense of the whole hearing experience. Disturbing background noises are effectively prevented through a sound-class-technology and the WindEx function furthermore ensures a comfortable reduction of wind noises."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Next to the new systems, new accessories were introduced as well. For instance, the Dex series enables Widex many useful gadgets in order to facilitate everyday life. “RC Dex2” is the smart remote control which is able to regulate the volume of the hearing aids while also adapting the speech comprehension according to the actual hearing experience. The “Phone Dex” is a wireless landline telephone that directly transfers incoming calls into the hearing aid. With help of the streamer “Uni Dex” the hearing system may be easily connected with different multimedia equipment: all you need to do is connecting the audio cable with streamer and pc, tv or your HiFi sound system. The “Com-Dex” neck bow serves as funk interface between hearing aid and the COM-App in order to ensure free-hand calling through Auto-Connect and in particular distinguishes itself through its battery life span (8 hours of calling, 8 days in stand-by mode)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "making-calls-and-watching-tv-directly-through-widex-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#making-calls-and-watching-tv-directly-through-widex-hearing-aids",
    "aria-label": "making calls and watching tv directly through widex hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Making calls and watching TV directly through Widex hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One hearing system, several connectivity options: Widex offers wearers of hearing aids extensive system solutions for wirelessly connecting its hearing systems to modern multimedia devices. Widex hearing aids, such as the Clear or Dream series, can connect to the TV."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To transfer TV sound to the hearing aids, Widex has developed its own radio technology: TV-Dex enables wireless audio transfer in lip-synch and in stereo quality. The Bluetooth interface from Mobile-Dex can be used to directly transfer cellphone calls to the hearing system. Phone-Dex can also be used to receive calls from landlines through the hearing system."), "\n", React.createElement(ButtonCta, {
    copy: "TRY WIDEX HEARING AIDS NOW",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
